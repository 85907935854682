import { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import { gsap } from "gsap";
import Link from "next/link";
import { ginger } from "../styles/fonts";
import routeConstants from "../constants/routes";

const Header = ({ transparent, whiteLogo, fontBlack, routes }) => {
    const [mobileScreen, setMobileScreen] = useState(false);
    const [menu, setMenu] = useState(true);
    const [width, setWidth] = useState("");
    const [route, setRoute] = useState("home");

    useEffect(() => {
        gsap.from("#header", {
            opacity: 0,
            delay: 1,
            y: 50,
            // x: "-100%",
        });
    }, []);

    useEffect(() => {
        window.addEventListener("resize", async () => {
            setWidth(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        const setWindowWidth = () => {
            let screenWidth = width ? width : window.innerWidth;
            if (screenWidth <= 750) {
                setMobileScreen(true);
            } else {
                setMobileScreen(false);
            }
        };
        setWindowWidth();
    }, [width]);

    useEffect(() => {
        switch (routes) {
            case routeConstants.whyUs:
                setRoute(routeConstants.whyUs);
                break;

            case routeConstants.howItWorks:
                setRoute(routeConstants.howItWorks);
                break;
            case routeConstants.blogs:
                setRoute(routeConstants.blogs);
                break;

            case routeConstants.brandStory:
                setRoute(routeConstants.brandStory);
                break;

            default:
                setRoute(routeConstants.home);
                break;
        }
    }, []);

    return mobileScreen ? (
        <div className={ginger.className}>
            <div
                className={styles.header}
                id="header"
                style={{ backgroundColor: `${transparent ? "transparent" : "white"}` }}
            >
                <div className={styles.outerContainer}>
                    <Link href="/" className={styles.leftLogo}>
                        {whiteLogo ? (
                            <img src={"/assets/logowhite.svg"} alt="next" />
                        ) : (
                            <img src={"/assets/logo.svg"} alt="next" />
                        )}
                    </Link>
                    <div className={styles.headerBtns}>
                        {/* <div className={styles.downloadBtn}>
                            <Link
                                href="https://play.google.com/store/apps/details?id=com.pillup"
                                className={styles.androidBtn}
                            >
                                <img src="../assets/android.svg"></img>
                            </Link>
                            <Link
                                href="https://apps.apple.com/in/app/pillup/id6447748859"
                                className={styles.iosBtn}
                            >
                                <img src="../assets/ios.svg"></img>
                            </Link>
                        </div> */}
                        <a
                            href="tel:+91 9560728666"
                            style={{ backgroundColor: "transparent" }}
                        >
                            <div className={styles.callBtn}>
                                <img src="../assets/callIcon.svg"></img>
                            </div>
                        </a>
                        <img
                            src={"/assets/hamburgurMenu.svg"}
                            onClick={() => setMenu(!menu)}
                            className={styles.hamburgur}
                        ></img>
                    </div>
                </div>
                <div className={styles.navOuterContainer}>
                    <div
                        className={styles.navContainer}
                        style={{ transform: `translateY(${menu ? "-220px" : "0"})` }}
                    >
                        <div className={styles.linkContainer}>
                            <Link
                                href="/WhyUs"
                                style={{
                                    textDecoration: "none",
                                    color: `${fontBlack ? "black" : "white"}`,
                                }}
                            >
                                <li
                                    className={styles.navItem}
                                    style={{
                                        display: "inline-block",
                                        padding: "0 5px 3px 5px",
                                        borderBottom: `3px solid ${route === routeConstants.whyUs ? "black" : "transparent"
                                            }`,
                                    }}
                                >
                                    Why Us
                                </li>
                            </Link>
                            <Link
                                href="/HowItWorks"
                                style={{
                                    textDecoration: "none",
                                    color: `${fontBlack ? "black" : "white"}`,
                                }}
                            >
                                <li
                                    className={styles.navItem}
                                    style={{
                                        display: "inline-block",
                                        padding: "0 5px 3px 5px",
                                        borderBottom: `3px solid ${route === routeConstants.howItWorks ? "black" : "transparent"}`,
                                    }}
                                >
                                    How It Works
                                </li>
                            </Link>
                            <Link
                                href="/blogs"
                                style={{
                                    textDecoration: "none",
                                    color: `${fontBlack ? "black" : "white"}`,
                                }}
                            >
                                <li
                                    className={styles.navItem}
                                    style={{
                                        display: "inline-block",
                                        padding: "0 5px 3px 5px",
                                        borderBottom: `3px solid ${route === routeConstants.blogs ? "black" : "transparent"
                                            }`,
                                    }}
                                >
                                    Blogs
                                </li>
                            </Link>
                            <Link
                                href="/BrandStory"
                                style={{
                                    textDecoration: "none",
                                    color: `${fontBlack ? "black" : "white"}`,
                                }}
                            >
                                <li
                                    className={styles.navItem}
                                    style={{
                                        display: "inline-block",
                                        padding: "0 5px 3px 5px",
                                        borderBottom: `3px solid ${route === routeConstants.brandStory ? "black" : "transparent"
                                            }`,
                                    }}
                                >
                                    Brand Story
                                </li>
                            </Link>
                            <Link
                                href="https://www.tatvacare.in/about/"
                                style={{
                                    textDecoration: "none",
                                    color: `${fontBlack ? "black" : "white"}`,
                                }}
                            >
                                <li
                                    className={styles.navItem}
                                    style={{
                                        display: "inline-block",
                                        padding: "0 5px 3px 5px",
                                        borderBottom: `3px solid ${route === routeConstants.aboutUs ? "black" : "transparent"
                                            }`,
                                    }}
                                >
                                    About Us
                                </li>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className={ginger.className}>
            <div
                className={styles.header}
                id="header"
                style={{ backgroundColor: `${transparent ? "transparent" : "white"}` }}
            >
                <Link href="/" className={styles.leftLogo}>
                    {whiteLogo ? (
                        <img src={"/assets/logowhite.svg"} alt="next" />
                    ) : (
                        <img src={"/assets/logo.svg"} alt="next" />
                    )}
                </Link>

                <div className={styles.navContainer}>
                    <Link
                        href="/WhyUs"
                        style={{
                            textDecoration: "none",
                            color: `${fontBlack ? "black" : "white"}`,
                            borderBottom: `3px solid ${route === routeConstants.whyUs ? "black" : "transparent"
                                }`,
                            padding: "10px 0",
                        }}
                    >
                        <li className={styles.navItem}>Why Us</li>
                    </Link>
                    <Link
                        href="/HowItWorks"
                        style={{
                            textDecoration: "none",
                            color: `${fontBlack ? "black" : "white"}`,
                            borderBottom: `3px solid ${route === routeConstants.howItWorks ? "black" : "transparent"
                                }`,
                            padding: "10px 0",
                        }}
                    >
                        <li className={styles.navItem}>How It Works</li>
                    </Link>
                    <Link
                        href="/blogs"
                        style={{
                            textDecoration: "none",
                            color: `${fontBlack ? "black" : "white"}`,
                            borderBottom: `3px solid ${route === routeConstants.blogs ? "black" : "transparent"
                                }`,
                            padding: "10px 0",
                        }}
                    >
                        <li className={styles.navItem}>Blogs</li>
                    </Link>
                    <Link
                        href="/BrandStory"
                        style={{
                            textDecoration: "none",
                            color: `${fontBlack ? "black" : "white"}`,
                            borderBottom: `3px solid ${route === routeConstants.brandStory ? "black" : "transparent"
                                }`,
                            padding: "10px 0",
                        }}
                    >
                        <li className={styles.navItem}>Brand Story</li>
                    </Link>
                    <Link
                        href="https://www.tatvacare.in/about/"
                        style={{
                            textDecoration: "none",
                            color: `${fontBlack ? "black" : "white"}`,
                            borderBottom: `3px solid ${route === routeConstants.aboutUs ? "black" : "transparent"
                                }`,
                            padding: "10px 0",
                        }}
                    >
                        <li className={styles.navItem}>About Us</li>
                    </Link>
                </div>
                <div className={styles.headerBtns}>
                    {/* <div className={styles.downloadBtn}>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.pillup"
                            className={styles.androidBtn}
                        >
                            <img src="../assets/android.svg"></img>
                        </a>
                        <a
                            href="https://apps.apple.com/in/app/pillup/id6447748859"
                            className={styles.iosBtn}
                        >
                            <img src="../assets/ios.svg"></img>
                        </a>
                    </div> */}
                    <a
                        href="tel:+91 9818360666"
                        style={{ backgroundColor: "transparent" }}
                    >
                        <div className={styles.callBtn}>
                            <img src="../assets/callIcon.svg"></img>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Header;
